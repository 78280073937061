export default `<body bgcolor="#FFFFFF" text="#000000" link="#000099" vlink="#003366" alink="#000099"><!-- BEGIN WAYBACK TOOLBAR INSERT -->
<script type="text/javascript" src="/static/js/timestamp.js?v=1553621510" charset="utf-8"></script>
<script type="text/javascript" src="/static/js/graph-calc.js?v=1553621510" charset="utf-8"></script>
<script type="text/javascript" src="/static/js/auto-complete.js?v=1553621510" charset="utf-8"></script>
<script type="text/javascript" src="/static/js/toolbar.js?v=1553621510" charset="utf-8"></script>
<style type="text/css">
body {
  margin-top:0 !important;
  padding-top:0 !important;
  /*min-width:800px !important;*/
}
.wb-autocomplete-suggestions {
    text-align: left; cursor: default; border: 1px solid #ccc; border-top: 0; background: #fff; box-shadow: -1px 1px 3px rgba(0,0,0,.1);
    position: absolute; display: none; z-index: 2147483647; max-height: 254px; overflow: hidden; overflow-y: auto; box-sizing: border-box;
}
.wb-autocomplete-suggestion { position: relative; padding: 0 .6em; line-height: 23px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 1.02em; color: #333; }
.wb-autocomplete-suggestion b { font-weight: bold; }
.wb-autocomplete-suggestion.selected { background: #f0f0f0; }
</style>
<script type="text/javascript">
__wm.bt(600,27,25,2,"web","http://www13.google.com/","1999-11-29",1996,"/static/");
</script><div class="wb-autocomplete-suggestions " style="left: 167px; top: 23px; width: 684px;"></div>
<!-- END WAYBACK TOOLBAR INSERT -->

<center>
<img src="https://web.archive.org/web/19991129021746im_/http://www.google.com/images/Title_HomPg.gif" width="600" height="130" border="0" usemap="#map1" alt="Google">
<map name="map1">
<area shape="RECT" coords="493,58,595,103" href="about.html" alt="About Google">
<area shape="RECT" coords="381,55,490,108" href="jobs.html" alt="Google Jobs">
</map>
</center>

<table width="95%" border="0"><tbody><tr><td>
<center>
<form action="/web/19991129021746/http://www.google.com/search" method="get" name="f">
Search the web using Google<br>
<input type="text" value="" framewidth="4" name="q" size="40"><br>
<nobr><input type="submit" value="Google Search">
<input name="sa" type="submit" value="I'm feeling lucky"></nobr><br>
</form>
</center>
</td></tr></tbody></table>

<script language="JavaScript">
<!--
document.f.q.focus();
// -->
</script>
<center>
<p><font face="arial,geneva,helvetica"><a href="pressrel/pressrelease6.html">
Google Wins PC Magazine's Technical Excellence Award<br>
for Innovation in Web Application Development</a></font></p></center>


<center>
<p><font face="arial,geneva,helvetica"><font size="-2">©1999 Google
Inc.</font></font></p></center>



<!--
     FILE ARCHIVED ON 02:17:46 Nov 29, 1999 AND RETRIEVED FROM THE
     INTERNET ARCHIVE ON 23:12:26 Apr 11, 2019.
     JAVASCRIPT APPENDED BY WAYBACK MACHINE, COPYRIGHT INTERNET ARCHIVE.

     ALL OTHER CONTENT MAY ALSO BE PROTECTED BY COPYRIGHT (17 U.S.C.
     SECTION 108(a)(3)).
-->
<!--
playback timings (ms):
  LoadShardBlock: 425.475 (24)
  esindex: 0.05 (7)
  CDXLines.iter: 352.106 (12)
  PetaboxLoader3.datanode: 223.92 (25)
  exclusion.robots: 1.716 (7)
  exclusion.robots.policy: 1.62 (7)
  RedisCDXSource: 18.612 (7)
  PetaboxLoader3.resolve: 114.583
  load_resource: 145.987
--></body>`;
