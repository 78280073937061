export default `
█▀█ █▀█ █░░ ▄▀█ █▀█ █ █▀   █▀█ ▄▀█ █▀█ ▀█▀ █▄░█ █▀▀ █▀█
█▀▀ █▄█ █▄▄ █▀█ █▀▄ █ ▄█   █▀▀ █▀█ █▀▄ ░█░ █░▀█ ██▄ █▀▄
================================================================
Mern Stack Developers | Rome, Italy | info@polarispartner.com
================================================================
We love to work with React, Node, Express, MongoDB, and more.
We are developers, engineers and designers that work together to create awesome digital products.

===============
LINKS
===============

E-mail: info@polarispartner.com
LinkedIn: https://www.linkedin.com/company/polaris-partner-it/
Phone: +39 06 9291 6155
`;
