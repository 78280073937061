const generateRows = () => {
  const rows = [
    {
      title: "E-mail",
      content: `<a href="mailto:info@polarispartner.com">info@polarispartner.com</a>`,
    },
    {
      title: "Phone",
      content: `<a href="tel:+390692916155">+39 06 9291 6155</a>`,
    },
  ];

  return rows
    .map(
      (r) => `
    <tr>
      <td bgcolor="black" width="80px" align="right">
        <font color="white" id="introduction">
          ${r.title}<br/>
        </font>
      </td>
      <td bgcolor="lightgrey">
        ${r.content}
      </td>
    </tr>
  `
    )
    .join("");
};

const marqueeGen = () =>
  [
    {
      href: "https://github.com/padraigfl/packard-belle-desktop",
      title: "Source Code",
    },
    {
      href: "https://www.npmjs.com/package/packard-belle",
      title: "Component Library",
    },
    {
      href: "https://www.linkedin.com/in/padraig-f-334b8764/",
      title: "LinkedIn",
    },
    { href: "https://www.buymeacoffee.com/padraig", title: "$$$?" },
    {
      href: "https://github.com/1j01/98#related-projects",
      title: "Similar projects",
    },
  ]
    .map((l) => `<a href="${l.href}" target="_blank" norel="noopen noreferrer">${l.title}</a>`)
    .join(" | ");

export default `
<style>
font * {font-family: 'Comic Sans MS' !important;}
blink {
  -webkit-animation: 1s linear infinite condemned_blink_effect; // for android
  animation: 1s linear infinite condemned_blink_effect;
}
@-webkit-keyframes condemned_blink_effect { // for android
  0% {
      visibility: hidden;
  }
  50% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}
@keyframes condemned_blink_effect {
  0% {
      visibility: hidden;
  }
  50% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}
</style>
<font size="4" >
  <table bgcolor="grey" width="100%">
    <thead>
      <tr>
        <td colspan="2" valign="center">
          <font size="7"><b>CONTACT US</b></font>
        </td>
      </tr>
    </thead>
    <tbody valign="top">
      ${generateRows()}
    </tbody>
    <tfoot valign="top">
      <tr><td bgcolor="black" align="right"><font color="white">Info</a></td><td>Polaris Partner SRL - Via G. Romita, 31 00072 Ariccia (RM), Italy - VAT: IT16274151006 </td>
     </tr>
    </tfoot>
  </table>
 </font>
`;
